html[data-level='anosfinais'] {
  .loading-middle {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding-bottom: 55px;
    padding-top: 65px;
    margin-left: -90px;
    width: 100%;

    .dot {
      animation-name: loader;
      animation-timing-function: ease-in-out;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      height: 20px;
      width: 20px;
      border-radius: 100% !important;
      background-color: black;
      position: absolute;
      border: 2px solid white;
    }

    .dot:first-child {
      background-color: #06803d;
      animation-delay: 0.5s;
    }

    .dot:nth-child(2) {
      background-color: #0ead2d;
      animation-delay: 0.4s;
    }

    .dot:nth-child(3) {
      background-color: #5bd93d;
      animation-delay: 0.3s;
    }

    .dot:nth-child(4) {
      background-color: #8cf005;
      animation-delay: 0.2s;
    }

    .dot:nth-child(5) {
      background-color: #ddfa00;
      animation-delay: 0.1s;
    }

    .dot:nth-child(6) {
      background-color: #fee000;
      animation-delay: 0s;
    }

    @keyframes loader {
      15% {
        transform: translateX(0);
      }
      45% {
        transform: translateX(180px);
      }
      65% {
        transform: translateX(180px);
      }
      95% {
        transform: translateX(0);
      }
    }
  }
}
