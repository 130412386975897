html[data-level='ensinomedio'] {
  .ResultsList {
    height: 100%;
    overflow: auto;
    background-color: $background-high;

    .resultsList--spinner {
      text-align: center;
    }

    .ResultsList--item {
      background-color: $background-high;
      padding: 20px;

      p {
        margin: 0;
      }
    }

    .ResultsList--item.list-group-item {
      background-color: $background-high;
      border-left: none;
      border-right: none;
      border-top: none;
    }

    .ResultsVideoItem {
      .media-body {
        @include media-breakpoint-down(xs) {
          margin-top: 15px;
        }
      }

      .ResultsVideoItem--title {
        cursor: pointer;
      }

      .ResultsVideoItem--item-caption {
        @include media-breakpoint-only(md) {
          text-overflow: ellipsis;
          overflow: hidden;
          max-height: 100px;

          &::after {
            content: '..';
            position: relative;
            left: 0;
          }
        }
      }

      .Thumbnail-video-util-wrapper {
        min-height: 70px;
      }
    }

    .media-heading {
      color: $quarternary;
      font-family: $font-family-headings-high;
      font-size: 1.5rem;
      font-weight: 700;
    }
  }
}
