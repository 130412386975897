@import '../../variables';

.Footer {
  height: 72px;
  box-shadow: 0 -6px 4px rgba(0, 0, 0, 0.1);

  @include media-breakpoint-down(md) {
    & > div.container {
      display: block;
    }

    height: 277px;

    &.navbar {
      padding: 0.938rem;
      display: block;
    }
  }

  @include media-breakpoint-down(xs) {
    height: 316px;
  }

  .footer-branding-container {
    .logo {
      height: 48px;

      @include media-breakpoint-down(xs) {
        height: 36px;
      }
    }

    .nav-link {
      display: inline-block;
      padding: 0;
    }

    .sponsor {
      border-left: 1px solid $gray-500;
      margin-left: 15px;
      padding-left: 15px;
      width: 140px;
    }
  }

  .footer-links-container {
    font-size: 0.75rem;
    margin-top: $spacer;
    width: 100%;
    text-align: right;

    a.nav-link {
      color: $black;

      &:hover,
      &:focus {
        color: $black;
        text-decoration: underline;
      }
    }

    @media screen and (min-width: 514px) {
      margin-top: -1rem;
      width: auto;
    }
  }

  .footer-links {
    li:first-child a {
      padding-left: 0;
    }

    li:last-child a {
      padding-right: 0;
    }

    &.navbar-nav {
      align-items: flex-end;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 1.438rem;
    }
  }
}
