.ResultsList {
  height: 100%;
  overflow: auto;

  .list-group {
    border-radius: 36px;
  }

  .resultsList--spinner {
    text-align: center;
  }

  .ResultsList--item {
    padding: 20px;

    p {
      margin: 0;
    }
  }

  .ResultsList--item.list-group-item {
    background-color: white;
    border-left: none;
    border-right: none;
    border-top: none;

    &:last-child {
      border-bottom: none;
    }
  }

  .ResultsVideoItem,
  .ResultsImageItem {
    .media-body {
      @include media-breakpoint-down(xs) {
        margin-top: 15px;
      }
    }

    .ResultsVideoItem--title,
    .ResultsImageItem--title {
      cursor: pointer;
    }

    .ResultsVideoItem--item-caption,
    .ResultsImageItem--item-caption {
      @include media-breakpoint-only(md) {
        text-overflow: ellipsis;
        overflow: hidden;
        max-height: 100px;

        &::after {
          content: '..';
          position: relative;
          left: 0;
        }
      }
    }

    .Thumbnail-video-util-wrapper {
      min-height: 70px;
    }
  }

  .media-heading {
    color: $quarternary;
    font-size: 1.5rem;
    font-weight: 600;
    font-family: $font-family-headings-elementary;
  }
}
