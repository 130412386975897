html[data-level='ensinomedio'] {
  .PageContent {
    padding-top: 60px;
    background-color: $white;
  }

  .PageContent--hero-banner {
    background: $primary-high-gradient;
    color: $white;
    margin-top: 0.81rem;
    padding: 12px 0;
    position: relative;
    top: 60px;

    .PageContent--title {
      font-family: $font-family-headings-high;
      margin-bottom: 2px;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.75);
      font-size: 28px;
      font-weight: 700;

      @include media-breakpoint-up(md) {
        font-size: 34px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 44px;
      }
    }
  }
}
