html[data-level='ensinomedio'] {
  .NavMenuItem {
    display: flex;
    align-items: center;
    width: 100%;
    height: 4.875rem;
    padding-bottom: 0.375rem;
    background: linear-gradient(90deg, rgba(0, 127, 153, 1) 0%, rgba(235, 245, 247, 1) 100%) left
      bottom no-repeat;
    background-size: 100% 0.375rem;

    img {
      width: 4.5rem;
      height: 4.5rem;
      margin-right: 0.75rem;
    }

    .text {
      line-height: 1.4rem;
      font-size: 1.25rem;
      color: $black;
      font-weight: bold;
      font-family: $font-family-headings-high;
    }
  }

  .NavMenuItem:hover {
    text-decoration: underline;
  }
}
