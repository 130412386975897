@import '../../variables';

.Accordion-Navigation {
  margin-bottom: 2rem;

  #list-group--root {
    border-radius: $border-radius;
    list-style: none;
    border: 1px solid $gray-300;
    overflow: hidden;

    .root {
      background-color: $primary !important;
      color: $white;
      padding: 0.375rem 0.75rem;
      cursor: pointer;

      .open-menu,
      .closed-menu {
        position: absolute !important;
        right: 0.75rem;
      }

      &.closed {
        .open-menu {
          margin-top: 24px;
          display: none;
        }
      }

      &.open {
        .closed-menu {
          margin-top: 24px;
          display: none;
        }
      }
    }

    li:last-child {
      border-bottom: 0;
    }

    ul {
      list-style: none;
      padding-left: 0;

      a {
        border-radius: 0;
        border-width: 1px 0 0 0;
        border-color: $gray-300;

        &.lvl-2 {
          padding-left: 1.5rem !important;
          background-color: $white;

          &:hover {
            background-color: $quarternary-highlight;
          }

          &:not(.selected) {
            padding-left: 2.76rem !important;
          }
        }

        &.lvl-1 {
          .absolute-right {
            position: absolute;
            right: 3px;
            top: 16px;
          }
        }
      }

      ul.collapse,
      ul.collapsing {
        padding: 0 !important;
        padding-left: 0 !important;
        margin: 0 !important;
        list-style: none;

        .list-group-item {
          border-radius: 0 !important;
        }
      }
    }

    .list-group--second-level {
      li:last-child {
        a {
          border-bottom: 0;
        }
      }
    }
  }
}

html[data-level='anosiniciais'] {
  .Accordion-Navigation {
    #list-group--root {
      border-radius: 12px;
      box-shadow: 0px 4px 12px #00000040;
    }
  }
}
