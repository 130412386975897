html[data-level='anosiniciais'] {
  .loading-elementary {
    animation: ease-in-out infinite;
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 45px;
    width: 100%;

    .bubble-1,
    .bubble-2,
    .bubble-3,
    .bubble-4 {
      border-radius: 50%;
      background-color: #f9f1d3;
      border: 3px solid #dc7f39;
      position: relative;
    }

    .bubble-1,
    .bubble-3 {
      width: 30px;
      height: 30px;
    }

    .bubble-1 {
      bottom: 10px;
      left: 10px;
      z-index: 1;
    }

    .bubble-2 {
      bottom: 12px;
      width: 45px;
      height: 45px;
      background-color: #f1ccaf;
    }

    .bubble-3 {
      bottom: 5px;
      right: 10px;
    }

    .bubble-4 {
      background-color: #f1ccaf;
      width: 15px;
      height: 15px;
      bottom: 5px;
      right: 18px;
    }

    .bubble-1,
    .bubble-2,
    .bubble-3,
    .bubble-4 {
      animation: bounce 1.5s ease-in-out infinite;
    }

    .bubble-2 {
      animation-delay: 0.2s;
    }

    .bubble-3 {
      animation-delay: 0.4s;
      z-index: 1;
    }

    .bubble-4 {
      animation-delay: 0.6s;
    }
  }
}
