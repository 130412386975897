@import '../../../variables';

.ResultsThumbnail {
  &.icon-backing-primary {
    background-color: $primary;
    border-radius: $border-radius;
  }

  .ResultsThumbnail-inner-wrapper {
    position: relative;

    img {
      width: 100%;
      height: auto;
    }
  }

  .ResultsThumbnail-video-util-wrapper {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    text-align: center;
    transition: 0.2s ease-in;
    width: 100%;

    .ResultsThumbnail-video-play-circle,
    .ResultsThumbnail-video-duration {
      color: $white;
    }

    .ResultsThumbnail-video-play-circle {
      height: 30%;
    }

    .ResultsThumbnail-video-duration {
      background-color: $gray-900;
      border-radius: $border-radius-sm;
      bottom: 0;
      padding: 4px;
      position: absolute;
      right: 0;
    }

    @media (hover: hover) {
      &:hover {
        background-color: rgba(0, 0, 0, 0);
      }
    }
  }
}

html[data-level='anosiniciais'] {
  .ResultsThumbnail {
    .ResultsThumbnail-inner-wrapper {
      img {
        border-radius: 20px;
      }

      .ResultsThumbnail-video-util-wrapper {
        border-radius: 20px;
      }
    }
  }
}
