.PageContent {
  padding-top: 60px;
}

.PageContent--hero-banner {
  background: url('../../assets/images/eb_prtgs_escola3_hero_bg.jpg');
  background-color: #c45500;
  color: $white;
  margin-top: 0.81rem;
  padding: 12px 0;
  position: relative;
  top: 60px;

  .PageContent--title {
    margin-bottom: 2px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.75);
    font-size: 24px;

    @include media-breakpoint-up(md) {
      font-size: 30px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 40px;
    }
  }
}
