@import '../../variables';

html[data-level='anosfinais'] {
  .PageContent {
    padding-top: 60px;
  }

  .PageContent--hero-banner {
    background: $primary-middle;
    color: $white;
    margin-top: 0.81rem;
    padding: 12px 0;
    position: relative;
    top: 60px;

    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 0.25rem;
      background-color: #253471;
      left: 0;
      bottom: -0.35rem;
    }

    .PageContent--title {
      font-family: $font-family-headings-middle;
      margin-bottom: 2px;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.75);
      font-size: 28px;
      font-weight: 700;

      @include media-breakpoint-up(md) {
        font-size: 34px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 44px;
      }
    }
  }
}
