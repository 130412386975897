@import '../../variables';

.SearchBox {
  background-color: $white;
  border-radius: $border-radius-sm;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  padding: 5px 5px 5px 0;
  @include media-breakpoint-down(sm) {
    padding: 0;
  }
  color: $global-text-color;
  input,
  select,
  button {
    border: none;

    &:focus {
      box-shadow: none;
    }
  }

  .input-group {
    flex-wrap: nowrap;
  }

  .SearchBox--input {
    .form-control {
      background-color: transparent;
    }
  }

  .form-control {
    &::placeholder {
      color: $gray-600;
    }
  }

  .custom-select {
    border-left: 3px solid $gray-200;
    border-radius: 0;
    color: $gray-800;

    @include media-breakpoint-down(sm) {
      border-left: none;
      border-top: 2px solid $gray-200;
    }

    &:focus {
      border-color: $gray-200;
    }

    &.form-control-sm {
      padding-left: $input-padding-x-sm;
    }

    &.form-control-lg {
      padding-left: $input-padding-x-lg;
    }
  }

  .SearchBox--submit {
    @include media-breakpoint-down(sm) {
      display: flex;
      align-content: center;
      flex-wrap: wrap;
    }

    .SearchBox--button.btn {
      border-radius: $border-radius-sm;

      @include media-breakpoint-down(sm) {
        padding: 3px 8px;
        font-size: 21px;
        border-radius: 8px;
      }
    }
  }

  [type='search']::-webkit-search-cancel-button {
    opacity: 1;
    -webkit-appearance: searchfield-cancel-button;
  }

  // Search box w/ search type dropdown

  &.has-dropdown {
    // Use a grid for mobile search boxes with dropdown

    .SearchBox--dropdown {
      select:focus-visible {
        outline: 2px auto Highlight;
        outline: 2px auto -webkit-focus-ring-color;
      }
    }

    @include media-breakpoint-down(sm) {
      .input-group {
        display: grid;
        grid-template-columns: auto min-content;
        grid-template-areas:
          'input submit'
          'dropdown dropdown';

        .SearchBox--input {
          border-bottom-left-radius: 0;
          grid-area: input;
          width: 100%;
        }

        .SearchBox--dropdown {
          grid-area: dropdown;
          margin-left: 0;
          margin-top: -1px;

          select {
            border-radius: 0 0 $border-radius $border-radius;

            &.form-control-sm {
              border-radius: 0 0 $border-radius-sm $border-radius-sm;
            }

            &.form-control-lg {
              border-radius: 0 0 $border-radius-lg $border-radius-lg;
            }
          }
        }

        .SearchBox--submit {
          grid-area: submit;
        }
      }

      // Autocomplete changes w/ dropdown

      .autocomplete-input {
        border-bottom-left-radius: 0;
      }
    }
  }

  // Search box w/ autocomplete

  .autocomplete {
    display: flex;
    flex: 1 1 auto;
    position: relative;
  }

  .autocomplete-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &.open {
      border-bottom-left-radius: 0;
    }
  }

  .autocomplete-suggestions-container {
    background-color: $input-bg;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: $zindex-dropdown;

    &.sm {
      border-bottom-left-radius: $border-radius-sm;
      border-bottom-right-radius: $border-radius-sm;
    }

    &.lg {
      border-bottom-left-radius: $border-radius-lg;
      border-bottom-right-radius: $border-radius-lg;
    }

    &.open {
      box-shadow: $box-shadow;
    }
  }

  .autocomplete-suggestion {
    cursor: pointer;
    padding: $input-padding-y $input-padding-x;
    color: $gray-600;

    &.sm {
      padding: $input-padding-y-sm $input-padding-x-sm;
    }

    &.lg {
      padding: $input-padding-y-lg $input-padding-x-lg;
    }

    &.highlighted {
      background-color: $dropdown-link-hover-bg;
    }
  }
}
