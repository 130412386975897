html[data-level='anosiniciais'] {
  .NavMenuItem {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.75rem;
    height: 5rem;
    margin-bottom: 0.75rem;

    img {
      width: 3.5rem;
      height: 3.5rem;
      margin-right: 0.25rem;
    }

    .text {
      line-height: 1.4rem;
      font-size: 1.25rem;
      color: $black;
      font-weight: 600;
      font-family: $font-family-headings-elementary;
    }
  }

  .NavMenuItem:hover {
    text-decoration: underline;
  }
}
