html[data-level='anosfinais'] {
  .Header.navbar {
    .left-navbar-container {
      .level-title {
        color: $primary-middle;
        font-family: $font-family-header;
      }
    }

    .topics-section-mobile {
      figure {
        font-family: $font-family-headings-middle;
      }
    }
  }
}
