@mixin button-variant-custom-disabled(
  $background,
  $border,
  $hover-background: darken($background, 7.5%),
  $hover-border: darken($border, 10%),
  $active-background: darken($background, 10%),
  $active-border: darken($border, 12.5%)
) {
  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: color-trans-yiq($background);
    background-color: desaturate(lighten($background, 33), 50);
    border-color: desaturate(lighten($background, 33), 50);
    opacity: 1 !important;
  }
}

@mixin button-outline-custom-variant(
  $color,
  $color-hover: color-yiq($color),
  $active-background: $color,
  $active-border: $color
) {
  &.disabled,
  &:disabled {
    color: lighten($color, 30%);
    border-color: desaturate(lighten($color, 33), 50);
    background-color: transparent !important;
    opacity: 1 !important;
  }
}

@mixin button-link-custom-variant($color) {
  font-weight: $font-weight-normal;
  color: $color;
  background-color: transparent;

  @include hover {
    color: darken($color, 7.5%);
    text-decoration: $link-hover-decoration;
    background-color: transparent;
    border-color: transparent;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    border-color: transparent;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: desaturate(lighten($color, 33), 50);
    pointer-events: none;
  }

  // No need for an active state here
}

@mixin pulse-on-hover($scaleVal: 1.1, $transition: 0.3s ease-in-out) {
  transition: $transition;
  &:hover {
    transform: scale($scaleVal);
  }

  @media (hover: none) {
    &:hover {
      transform: none;
    }
  }
}

@mixin high-level-divider($variant: false) {
  width: 100%;
  height: 0.375rem;
  border-radius: 0.25rem;
  position: relative;
  margin: 0.5rem 0 1.5rem 0;

  @if $variant == true {
    background-color: $white;
  } @else {
    background-color: $orange-high;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 2.5rem;
    height: 0.375rem;
    border-radius: 0.25rem;

    @if $variant == true {
      background-color: $orange-high;
    } @else {
      background-color: $primary-high;
    }
  }
}
