html[data-level='ensinomedio'] {
  .loading-high {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding-bottom: 55px;
    padding-top: 65px;
    width: 100%;

    .loader {
      position: relative;

      .dot {
        -webkit-animation-name: movement;
        animation-name: movement;
        -webkit-animation-duration: 2s;
        animation-duration: 2s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        height: 10px;
        position: absolute;
        top: -10px;
        transform: translate3d(0, -20px, 0) scale(1);
        width: 10px;
      }

      .dot:nth-of-type(1) {
        -webkit-animation-delay: -0.1s;
        animation-delay: -0.1s;
        left: 87.5px;
      }

      .dot:nth-of-type(1)::before {
        -webkit-animation-delay: -0.1s;
        animation-delay: -0.1s;
      }

      .dot:nth-of-type(2) {
        -webkit-animation-delay: -1.2s;
        animation-delay: -1.2s;
        left: 87.5px;
      }

      .dot:nth-of-type(2)::before {
        -webkit-animation-delay: -1.2s;
        animation-delay: -1.2s;
      }

      .dot:nth-of-type(3) {
        -webkit-animation-delay: -0.3s;
        animation-delay: -0.3s;
        left: 62.5px;
      }

      .dot:nth-of-type(3)::before {
        -webkit-animation-delay: -0.3s;
        animation-delay: -0.3s;
      }

      .dot:nth-of-type(4) {
        -webkit-animation-delay: -1.4s;
        animation-delay: -1.4s;
        left: 62.5px;
      }

      .dot:nth-of-type(4)::before {
        -webkit-animation-delay: -1.4s;
        animation-delay: -1.4s;
      }

      .dot:nth-of-type(5) {
        -webkit-animation-delay: -0.5s;
        animation-delay: -0.5s;
        left: 37.5px;
      }

      .dot:nth-of-type(5)::before {
        -webkit-animation-delay: -0.5s;
        animation-delay: -0.5s;
      }

      .dot:nth-of-type(6) {
        -webkit-animation-delay: -1.6s;
        animation-delay: -1.6s;
        left: 37.5px;
      }

      .dot:nth-of-type(6)::before {
        -webkit-animation-delay: -1.6s;
        animation-delay: -1.6s;
      }

      .dot:nth-of-type(7) {
        -webkit-animation-delay: -0.7s;
        animation-delay: -0.7s;
        left: 12.5px;
      }

      .dot:nth-of-type(7)::before {
        -webkit-animation-delay: -0.7s;
        animation-delay: -0.7s;
      }

      .dot:nth-of-type(8) {
        -webkit-animation-delay: -1.8s;
        animation-delay: -1.8s;
        left: 12.5px;
      }

      .dot:nth-of-type(8)::before {
        -webkit-animation-delay: -1.8s;
        animation-delay: -1.8s;
      }

      .dot:nth-of-type(9) {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
        left: -12.5px;
      }

      .dot:nth-of-type(9)::before {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
      }

      .dot:nth-of-type(10) {
        -webkit-animation-delay: -2s;
        animation-delay: -2s;
        left: -12.5px;
      }

      .dot:nth-of-type(10)::before {
        -webkit-animation-delay: -2s;
        animation-delay: -2s;
      }

      .dot:nth-of-type(11) {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
        left: -37.5px;
      }

      .dot:nth-of-type(11)::before {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
      }

      .dot:nth-of-type(12) {
        -webkit-animation-delay: -2.2s;
        animation-delay: -2.2s;
        left: -37.5px;
      }

      .dot:nth-of-type(12)::before {
        -webkit-animation-delay: -2.2s;
        animation-delay: -2.2s;
      }

      .dot:nth-of-type(13) {
        -webkit-animation-delay: -1.3s;
        animation-delay: -1.3s;
        left: -62.5px;
      }

      .dot:nth-of-type(13)::before {
        -webkit-animation-delay: -1.3s;
        animation-delay: -1.3s;
      }

      .dot:nth-of-type(14) {
        -webkit-animation-delay: -2.4s;
        animation-delay: -2.4s;
        left: -62.5px;
      }

      .dot:nth-of-type(14)::before {
        -webkit-animation-delay: -2.4s;
        animation-delay: -2.4s;
      }

      .dot:nth-of-type(15) {
        -webkit-animation-delay: -1.5s;
        animation-delay: -1.5s;
        left: -87.5px;
      }

      .dot:nth-of-type(15)::before {
        -webkit-animation-delay: -1.5s;
        animation-delay: -1.5s;
      }

      .dot:nth-of-type(16) {
        -webkit-animation-delay: -2.6s;
        animation-delay: -2.6s;
        left: -87.5px;
      }

      .dot:nth-of-type(16)::before {
        -webkit-animation-delay: -2.6s;
        animation-delay: -2.6s;
      }

      .dot::before {
        -webkit-animation-name: size-opacity;
        animation-name: size-opacity;
        -webkit-animation-duration: 2s;
        animation-duration: 2s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: ease;
        animation-timing-function: ease;
        background: #007f99;
        border-radius: 50%;
        content: '';
        display: block;
        height: 100%;
        width: 100%;
      }

      .dot:nth-of-type(even)::before {
        background-color: #006073;
        box-shadow: inset 0 0 4px #003540;
      }

      @-webkit-keyframes movement {
        0% {
          transform: translate3d(0, -20px, 0);
        }
        50% {
          transform: translate3d(0, 20px, 0);
        }
        100% {
          transform: translate3d(0, -20px, 0);
        }
      }

      @keyframes movement {
        0% {
          transform: translate3d(0, -20px, 0);
        }
        50% {
          transform: translate3d(0, 20px, 0);
        }
        100% {
          transform: translate3d(0, -20px, 0);
        }
      }

      @-webkit-keyframes size-opacity {
        0% {
          opacity: 1;
          transform: scale(1);
        }
        25% {
          transform: scale(1.5);
        }
        50% {
          opacity: 1;
        }
        75% {
          opacity: 0.35;
          transform: scale(0.5);
        }
        100% {
          opacity: 1;
          transform: scale(1);
        }
      }

      @keyframes size-opacity {
        0% {
          opacity: 1;
          transform: scale(1);
        }
        25% {
          transform: scale(1.5);
        }
        50% {
          opacity: 1;
        }
        75% {
          opacity: 0.35;
          transform: scale(0.5);
        }
        100% {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}
