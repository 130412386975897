@import '../../variables';

html[data-level='anosfinais'] {
  .HelpPage {
    h2.toc-level-0,
    h2.toc-level-1 {
      // !important rule is needed since the original elementary file has it
      color: $link-color !important;
      font-weight: $font-weight-bold !important;
      font-family: $font-family-headings-middle !important;
    }

    .card {
      border-radius: 0;
      background-color: white;
    }

    .Accordion-Navigation {
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

      #list-group--root {
        border-radius: 0;
        border: none;

        li.help-page-last-group:last-child {
          a {
            border-bottom: none;
          }
        }

        .help-page-last-group {
          a.lvl-1 {
            border-bottom: none;
          }

          a.lvl-1.active {
            border-bottom: 1px solid black;
          }
        }

        a {
          display: inline-block;
          padding: 12px 20px;
          text-decoration: none;
          width: 100%;
          color: $link-color;
          border-bottom: 1px solid black;
        }

        .list-group-item {
          border-top: none;

          &:hover {
            background-color: $quarternary-highlight-middle;
          }
        }

        .active {
          color: black;
          background-color: $secondary-middle;

          &:hover {
            background-color: $secondary-middle;
          }
        }
      }
    }
  }
}
