@import '../../variables';
@import './Header.elementary.scss';
@import './Header.middle.scss';
@import './Header.high.scss';

a.skip-content {
  display: block;
  margin: auto;
  // Overrides bootstrap default widths
  width: 100% !important;
  border: rgb(0, 109, 193) 1px solid;
  background-color: rgb(242, 242, 242);
  text-align: center;
  color: rgb(0, 109, 193);
  font-size: 16px;
  font-weight: 400;
  line-height: 50px;
  height: 50px;
}
