.Header.navbar {
  .header-search {
    box-shadow: none;
    border: 1px solid;
    margin-right: 0.5rem;
  }

  .left-navbar-container {
    align-items: center;
    height: 56px;
    display: flex;

    .logo {
      object-fit: cover;
      height: 50px;
    }

    .level-title {
      color: $secondary;
      font-size: 1.3rem;
      line-height: 1.5rem;
      text-decoration: none;
      padding-left: 15px;
      border-left: 1px solid $gray-500;
      width: 7.5rem;
      letter-spacing: 2px;
      font-family: $font-family-header;
    }

    .sponsor {
      border-left: 1px solid $gray-500;
      margin-left: 15px;
      padding-left: 15px;
    }

    @include media-breakpoint-down(md) {
      .logo {
        border-right: none;
      }

      .level-title {
        display: none;
      }
    }
  }

  .topic-menu-icon {
    font-size: 1.625rem;
    margin-right: 0.125rem;
    margin-left: 0.125rem;
  }

  .topics-section-mobile {
    display: inline-flex;
    text-align: center;
    margin-right: -0.5rem;

    figure {
      margin-inline-end: 0.5rem;
      font-size: 0.75rem;
      color: $black;
      font-weight: 600;
      font-family: $font-family-headings-elementary;
      margin-bottom: 0;

      @include media-breakpoint-down(xs) {
        font-size: 0.65rem;
      }
    }

    img {
      max-width: 100%;
      margin-bottom: 0.5rem;
      border-radius: 0.5rem;
    }
  }

  @include media-breakpoint-down(sm) {
    .left-navbar-container {
      width: 80%;
    }
  }

  @include media-breakpoint-down(xs) {
    .left-navbar-container {
      width: 65%;
    }
  }

  .right-navbar-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;

    button.navbar-toggler {
      margin-left: 15px;
      padding: 0.25rem;
      font-size: 1.1rem;
      background-color: $new-primary-btn;
    }
  }

  .mobile-sponser {
    text-align: center;
  }

  .dropdown-item {
    color: $link-color;

    &:hover {
      text-decoration: underline;
    }
  }

  .dropdown-item-disabled {
    color: $gray-600 !important;

    &:hover {
      text-decoration: none !important;
      background-color: transparent !important;
    }
  }

  .navbar-item {
    margin-right: 0 5px;

    a.nav-link {
      color: $black;

      &:hover,
      &:focus,
      &.active {
        color: $black;
        text-decoration: underline;
      }
    }
  }
}
